import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Stage from 'ui-kit/stage';
import Section from 'ui-kit/section';
import Container from 'ui-kit/container';
import Intro from 'ui-kit/intro';
import { P, Headline } from 'ui-kit/typography';
import Storelocator from 'ui-kit/storelocator';
import Teaserbox from 'ui-kit/teaserbox';

import Layout from '../components/layout';

const OriginAndQualityPage = ({ data }) => {
    /** Einzelbilder */
    const stageImage = data.stageImage.childImageSharp;
    const strohschweinSignet = data.strohschweinSignet.childImageSharp;

    return (
        <Layout description="Herkunft und Qualität">
            <Stage
                image={stageImage}
                tag="Herkunft & Qualität"
                headline={{ text: 'Neue Highlights für Feinschmecker!', level: 'h1' }}
                imageAlt="Dummy"
                bottomShade
            />
            <Section>
                <Intro title="Gehen Sie auf eine kulinarische Entdeckungsreise!" gap="xxxl">
                    <P fontSize="m">
                        Wenn Schweine in so einem natürlichen und sorgfältig behütetem Umfeld
                        aufwachsen, fühlen sie sich nicht nur sauwohl, sondern gedeihen auch
                        prächtig. Das Strohschwein liefert uns besonders gutes, saftiges Fleisch
                        voller Aroma und Geschmack - und in herausragender Qualität! Was unsere
                        Metzgermeister vor die schöne neue Aufgabe stellt, nur das Beste daraus zu
                        machen.
                    </P>
                </Intro>
                <Container size="l" grid layout="4-4-4" cols="3" alignItems="center" gap="xxxl">
                    <div />
                    <Teaserbox
                        image={strohschweinSignet}
                        imageAlt="Premium Fleisch Qualität vom Strohschwein"
                        centered
                        noHover
                        imageCritical
                    />
                    <div />
                </Container>
            </Section>
            <Section bg="gray" textAlign="center" style={{ 'padding-bottom': '1rem' }}>
                <Headline level="h3" textAlign="center">
                    Ein neuer, einzigartiger Genuss!
                    <br />
                    Ein Geschmackserlebnis, das Sie begeistern wird!
                </Headline>
                <P fontSize="m" textAlign="center" gap="xl">
                    Entdecken Sie jetzt unsere leckeren Fleisch- und Wurstpezialitäten vom
                    Bayerischen Strohschwein.
                </P>
                <P fontSize="m" textAlign="center" gap="xxxl">
                    Exklusiv bei Ihrer Qualitäts-Fachmetzgerei der Südbayerischen Fleischwaren.
                </P>
                <Headline level="h2">Händler</Headline>
            </Section>
            <Storelocator
                stores={data.allRetailerJson.edges.map(({ node }) => node)}
                displayInViewportHeight={false}
            />
        </Layout>
    );
};

OriginAndQualityPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        allRetailerJson: PropTypes.object,
        strohschweinSignet: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "origin-and-quality-page/stage.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        strohschweinSignet: file(relativePath: { eq: "strohschwein-logo.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 640, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allRetailerJson {
            edges {
                node {
                    id
                    name
                    street
                    zip
                    city
                    lat
                    lng
                }
            }
        }
    }
`;

export default OriginAndQualityPage;
